<template>
  <DraftGuard :work-in-progress="workInProgress">
    <div class="new-contact-wrapper">
      <v-tabs
        hide-slider
        background-color="#fafafa"
        :value="selectedViewTab"
        @change="onSelectViewTabChange"
      >
        <v-tab class="header-tab-item">
          <div :class="selectedViewTab === 0 ? 'header-tab-item-selected' : ''">
            {{ $t("newContact.add") }}
          </div>
        </v-tab>
        <v-tab-item>
          <div class="new-contact-details-wrapper">
            <ContactEditor
              :contact="contact"
              :validationErrors="errorMessages"
              @onChange="onValueChange"
            />
            <div class="buttons">
              <v-btn
                color="roundButton roundButtonText--text"
                rounded
                @click="onCancelButtonClick"
              >
                {{ $t("global.cancel") }}
              </v-btn>
              <v-btn
                rounded
                color="roundButton roundButtonText--text"
                :loading="isLoading"
                @click="onAddContactButtonClick"
              >
                {{ $t("global.add") }}
              </v-btn>
            </div>
            <ModalWindow ref="confirmModal" />
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </DraftGuard>
</template>

<script>
import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";

import { mailChannel, smsChannel } from "@/models/channel.js";
import {
  validateContactBeforeSave,
  validateEmailOrPhoneCustomList,
} from "@/mixins/validators";

import { EmitError, EmitSuccess } from "@/eventBus.js";

import CrmCustomersService from "@/services/CrmCustomersService";
import NewCrmCustomerService from "@/services/NewCrmCustomerService";

import DraftGuard from "@/components/DraftGuard";
import ContactEditor from "@/components/Contacts/ContactEditor";
import ModalWindow from "@/components/Shared/ModalWindow";

export default {
  name: "NewContact",
  components: {
    ContactEditor,
    ModalWindow,
    DraftGuard,
  },
  data() {
    return {
      workInProgress: true,
      isLoading: false,
      selectedViewTab: 0,
      contact: this.initialContact(),
      errorMessages: {},
      emailsToValidateIfAlreadyUse: [],
      phonesToValidateIfAlreadyUse: [],
      validatedCorrectedEmails: [],
      validatedCorrectedPhones: [],
    };
  },
  watch: {
    "contact.firstname"(newValue) {
      this.errorMessages.firstname = !newValue
        ? this.$t("rules.required")
        : null;
    },
    "contact.surname"(newValue) {
      this.errorMessages.surname = !newValue ? this.$t("rules.required") : null;
    },
    "contact.type"(newValue) {
      this.errorMessages.type = !newValue ? this.$t("rules.required") : null;
    },
    "contact.emails"(newValues) {
      this.validateEmailsAfterChange(newValues);
    },
    "contact.phones"(newValues) {
      this.validatePhonesAfterChange(newValues);
    },
    emailsToValidateIfAlreadyUse(newItems) {
      if (Array.isArray(newItems) && newItems.length > 0) {
        const correctedEmails = [...this.validatedCorrectedEmails];
        const errors = this.errorMessages.emails;

        this.validateIfAlreadyUsed(newItems, EMAIL, errors, correctedEmails);
      }
    },
    phonesToValidateIfAlreadyUse(newItems) {
      if (Array.isArray(newItems) && newItems.length > 0) {
        const correctedPhones = [...this.validatedCorrectedPhones];
        const errors = this.errorMessages.phones;

        this.validateIfAlreadyUsed(
          newItems,
          MOBILEPHONE,
          errors,
          correctedPhones
        );
      }
    },
  },
  methods: {
    onSelectViewTabChange(index) {
      this.selectedViewTab = index;
    },
    initialContact() {
      return {
        firstname: "",
        surname: "",
        description: "",
        type: null,
        insertDate: this.$moment(),
        emails: [mailChannel()],
        phones: [smsChannel()],
        owner: null,
        organization: null,
        department: null,
        groups: [],
        city: null,
        street: null,
        houseNo: null,
        postCode: null,
        relatedContacts: [],
      };
    },
    onValueChange(newValue) {
      this.contact = { ...this.contact, ...newValue };
    },
    onCancelButtonClick() {
      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("common.closeConfirm")
        )
        .then((result) => {
          if (result) {
            this.workInProgress = false;
            this.$nextTick(() => this.$router.push({ name: "contacts" }));
          }
        });
    },
    onAddContactButtonClick() {
      const isValidationError = this.validateBeforeSave();

      if (!isValidationError) {
        this.errorMessages = {};
        this.isLoading = true;
        this.contact.insertDate = this.$moment().format();

        NewCrmCustomerService.Create(this.contact)
          .then(async () => {
            EmitSuccess(
              this.$t("contacts.createSuccess", {
                name: this.contact.firstname,
                surname: this.contact.surname,
              })
            );

            this.workInProgress = false;
            await this.$nextTick();
            this.$router.push({ name: "contacts" });
          })
          .catch(() => {
            EmitError(this.$t("contacts.createError"));
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    validateBeforeSave() {
      const { isValidationError, errors } = validateContactBeforeSave(
        this.contact,
        { ...this.errorMessages },
        this
      );

      this.errorMessages = errors;
      return isValidationError;
    },
    validateEmailsAfterChange(newValues) {
      if (
        (newValues && newValues[0] && newValues[0].text !== "") ||
        newValues.length > 1
      ) {
        const { errors, itemsToCheckIfAlreadyUsed } =
          validateEmailOrPhoneCustomList(newValues, EMAIL, this);

        this.errorMessages.emails = errors;

        if (itemsToCheckIfAlreadyUsed.length > 0) {
          this.emailsToValidateIfAlreadyUse = itemsToCheckIfAlreadyUsed.filter(
            (itemToCheck) =>
              !this.validatedCorrectedEmails.includes(itemToCheck.value)
          );
        }
      } else {
        this.errorMessages.emails = [];
        this.emailsToValidateIfAlreadyUse = [];
      }
    },
    validatePhonesAfterChange(newValues) {
      if (
        (newValues && newValues[0] && newValues[0].text !== "") ||
        newValues.length > 1
      ) {
        const { errors, itemsToCheckIfAlreadyUsed } =
          validateEmailOrPhoneCustomList(newValues, MOBILEPHONE, this);

        this.errorMessages.phones = errors;

        if (itemsToCheckIfAlreadyUsed.length > 0) {
          this.phonesToValidateIfAlreadyUse = itemsToCheckIfAlreadyUsed.filter(
            (itemToCheck) =>
              !this.validatedCorrectedPhones.includes(itemToCheck.value)
          );
        }
      } else {
        this.errorMessages.phones = [];
        this.phonesToValidateIfAlreadyUse = [];
      }
    },
    validateIfAlreadyUsed(itemsToValidate, type, errors, correctedItems) {
      const promises = [];

      itemsToValidate.forEach((item) => {
        promises.push(
          CrmCustomersService.CheckIsMediumTaken(
            item.value,
            type,
            this.contact.id
          )
        );
      });

      Promise.all(promises).then((responses) => {
        itemsToValidate.forEach((item, index) => {
          if (responses[index]) {
            errors[item.index] =
              type === EMAIL
                ? this.$t("rules.isUniqueFirstEmail")
                : this.$t("rules.isUniqueFirstPhone");
          } else {
            correctedItems.push(item.value);
          }
        });

        if (type === EMAIL) {
          this.errorMessages = {
            ...this.errorMessages,
            emails: errors,
          };
          this.validatedCorrectedEmails = correctedItems;
        } else if (type === MOBILEPHONE) {
          this.errorMessages = {
            ...this.errorMessages,
            phones: errors,
          };
          this.validatedCorrectedPhones = correctedItems;
        }
      });
    },
  },
};
</script>

<style scope lang="scss">
.new-contact-wrapper {
  margin: 20px -12px -12px -12px;

  .v-slide-group__content {
    padding-top: 2px; // to display box shadow on top
    // border-bottom: 1px solid #e0e0e0;

    .header-tab-item {
      min-width: 150px;
      border-radius: 0 20px 0 0;
      background-color: $tab-item-background;
      font-weight: bold;
      margin-right: 2px; // to display box shadow on right
      margin-bottom: -2px; // to hide box shadow on tab's bottom
      box-shadow: 1px -1px 5px -3px rgba(66, 68, 90, 1);

      &.v-tab--active {
        background-color: white;
      }

      .icon-wrapper {
        margin-left: 5px;
      }

      .go-back-arrow {
        margin-left: -20px;
        margin-right: 10px;
      }

      .header-tab-item-selected {
        color: $text-title;
      }
    }

    .header-tab-item::before {
      border-radius: 0 20px 0 0;
    }
  }

  .new-contact-details-wrapper {
    margin: 20px 0 30px 0;

    .buttons {
      float: right;
      margin: 30px 30px 30px 0;

      .v-btn {
        margin-left: 15px;
      }
    }
  }
}
</style>
